
export default {
	name: "CoreBlockText",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		blockContent() {
			return this.settings?.text || null;
		},
		blockTag() {
			return this.settings?.tag || "p";
		},
	},
};
